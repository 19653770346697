import React from 'react';
import PropTypes from 'prop-types';

import iconDownload from '../images/icons/buttons/Download.svg'
import '../styles/components/rectangleLink.scss';

const RectangleLink = (props) => {
  const {
    download = false,
    classNameLink,
    iconDownloadBool = true,
    label = '',
    link = '',
    target = '_self',
    title = '',
  } = props;

  return (
    <div className="Rectangle_link">
      <div className="Rectangle_link-Cell">
        {label ? (
          <label className="Rectangle_link-Label">{label}</label>
        ) : null}

        <a
          className={`Rectangle_link-Content ${classNameLink}`}
          download={download}
          href={link}
          target={target}
        >
          {iconDownloadBool ? (
            <img className="Rectangle_link-Icon_download" src={iconDownload} alt=""/>
          ) : null}

          <span dangerouslySetInnerHTML={{ __html: title }} />
        </a>
      </div>
    </div>
  );
};

export const RectangleLinkPropTypes = {
  download: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  title: PropTypes.string,
};

RectangleLink.propTypes = RectangleLinkPropTypes;

export default RectangleLink;

import React from "react"

import '../styles/components/clarification.scss';
import PropTypes from "prop-types";

const Clarification = ({ label = '', text = '', background = 'Primary', fontStyle = 'Normal' }) => {
    const classesContent = React.useMemo(() => {
        let cls = 'Clarification-Content'

        if (background) {
            cls += ` Clarification-Content-${background}`
        }

        if (fontStyle) {
            cls += ` Clarification-Content-${fontStyle}`
        }

        return cls
    }, [background, fontStyle])

    return (
        <div className="Clarification">
            <div className="Clarification-Cell">
                {label && <label className="Clarification-Label">{label}</label>}
                <div className={classesContent} dangerouslySetInnerHTML={{__html: text}}>
                </div>
            </div>
        </div>
    )
};

export const ClarificationPropTypes = {
    label: PropTypes.string,
    text: PropTypes.string,
    background: PropTypes.oneOf(['Primary', 'Grey']),
    fontStyle: PropTypes.oneOf(['Normal', 'Italic']),
};

Clarification.propTypes = ClarificationPropTypes;

export default Clarification;

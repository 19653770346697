import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage';

import RectangleLink from "../../components/RectangleLink";
import Clarification from "../../components/Clarification";
import Footnote from "../../components/Footnote";

import enMessages from '../../i18n/legal/fatcaCompliance/en.js'
import ruMessages from '../../i18n/legal/fatcaCompliance/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const FatcaCompliancePage = ({ pathContext: { locale } }) => {
    const section = 'fatcaCompliance';

    return (
        <TemplateLegalPage
            locale={locale}
            section={section}
            messages={messages[locale]}
            messagesMain={messagesMain[locale]}
        >
            <div className="LegalInformation-Text">{messages[locale].content.information}</div>
            <Clarification
                text={messages[locale].content.clarification}
                background={'Grey'}
                fontStyle={'Italic'}
            />
            <div className="LegalInformation-Text" dangerouslySetInnerHTML={{ __html: messages[locale].content.theMainGoal }}/>

            <div className="LegalInformation-Text">
                <RectangleLink
                    title={messages[locale].content.taxResidentStatusForAnIndividual.title}
                    link={messages[locale].content.taxResidentStatusForAnIndividual.link}
                    target="_blank"
                />
                <RectangleLink
                    title={messages[locale].content.taxResidentStatusOfALegalEntity.title}
                    link={messages[locale].content.taxResidentStatusOfALegalEntity.link}
                    target="_blank"
                />
            </div>

            <div className="LegalInformation-Text">
                <Footnote
                    fontStyle={'Italic'}
                    content={messages[locale].content.footnote}/>
            </div>

            <div className="Line-bottom" />

            <div className="LegalInformation-title">{messages[locale].content.informationForClients}</div>
            <div className="LegalInformation-Text">
                {messages[locale].content.additionalDocuments}

                <Footnote content={messages[locale].content.additionalDocumentsFootnote}/>
                <Footnote content={messages[locale].content.subAdditionalDocumentsFootnote} numbering={1}/>
            </div>

            <div className="Line-bottom" />

            <div className="LegalInformation-title">{messages[locale].content.legalEntities}</div>
            <div className="LegalInformation-Text">
                {messages[locale].content.legalEntitiesDescription}
            </div>
            <div className="LegalInformation-Text">
                {messages[locale].content.legalEntitiesLink.map(item => <RectangleLink
                    title={item.title}
                    link={item.link}
                    target="_blank"
                />)}
            </div>
        </TemplateLegalPage>
    )
};

export default FatcaCompliancePage;

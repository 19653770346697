import W_8BEN_E from '../../../assets/files/ru/W-8BEN-E.pdf';
import W_8IMY from '../../../assets/files/ru/W-8IMY.pdf';
import glossary from '../../../assets/files/ru/Glossary.pdf';

export default {
    seo: {
      title: 'Соответствие FATCA | Банк 131',
      description: 'Соответствие FATCA'
    },
    title: 'Соответствие FATCA',
    content: {
         information: 'Настоящим АО «Банк 131» информирует Вас, что в соответствии с Федеральным Законом ' +
             'от 28.06.2014 № 173-ФЗ «Об особенностях осуществления финансовых операций с иностранными гражданами и ' +
             'юридическими лицами, о внесении изменений в Кодекс Российской Федерации ' +
             'об административных правонарушениях и признании утратившими силу отдельных положений законодательных ' +
             'актов Российской Федерации» и FATCA (Foreign Account Tax Compliance Act) — закона США, Глава 4 ' +
             'Налогового кодекса США, который обязывает неамериканские финансовые институты идентифицировать ' +
             'клиентов-налогоплательщиков США, их выгодоприобретателей и (или) лиц, прямо или косвенно их ' +
             'контролирующих и передавать информацию по денежным средствам на их счетах в Налоговую службу ' +
             'США (Internal Revenue Service, IRS), Банк осуществил регистрацию в Налоговой службе США (IRS).',
         clarification: 'АО «Банк 131» зарегистрирован в качестве участвующего финансового института (Participating ' +
             'Foreign Financial Institution, PFFI) и получил глобальный идентификационный номер посредника ' +
             '(Global Intermediary Identification Number, GIIN): 4NB0VB.99999.SL643.',
         theMainGoal: 'Основной целью Закона FATCA является противодействие уклонению от налогообложения в США ' +
             'доходов Налогоплательщиков США, полученных через финансовые институты за пределами США.<br/>' +
             '<br/>' +
             'С 09.01.2020 Банк осуществляет сбор сведений, позволяющих определить принадлежность обратившегося лица ' +
             'в Банк, для установления деловых взаимоотношений, к лицам, на которых распространяется законодательство ' +
             'иностранного государства о налогообложении иностранных счетов.<br/>' +
             '<br/>' +
             'При заключении договора по любой услуге Вам необходимо заполнить анкету Банка, содержащую ' +
             'дополнительные сведения FATCA (далее — Анкета). А также, для подтверждения налогового резидентства ' +
             'работник АО «Банк 131» вправе задать уточняющие вопросы, и попросить заполнить форму самосертификации:',
         taxResidentStatusForAnIndividual: {
             title: 'Форма подтверждения статуса налогового резидента для физического лица (в том числе лица, ' +
                 'занимающегося частной практикой, и индивидуального предпринимателя);',
             link: '/legal/ru/PERSONAL_SELF-CERTIFICATION_FORM(FATCA).doc'
         },
         taxResidentStatusOfALegalEntity: {
             title: 'Форма подтверждения статуса налогового резидента (для юридического лица, в том числе ' +
                 'иностранной организации без образования юридического лица).',
             link: '/legal/ru/SELF-CERTIFICATION_FORM_OF_LEGAL_ENTITY(FATCA).doc'
         },
         footnote: [
             {
                 text: 'Обращаем Ваше внимание, что Банк не консультирует клиентов по вопросам применения ' +
                     'Закона FATCA. В случае возникновения вопросов рекомендуем Вам проконсультироваться ' +
                     'с профессиональным налоговым консультантом. А также, в случае возникновения вопросов относительно ' +
                     'действия закона FATCA и его применения, рекомендуем Вам обратиться к веб-сайту Налоговой службы ' +
                     'США: <a href="https://www.irs.gov/" target="_blank">https://www.irs.gov/</a>'
             }
         ],
         informationForClients: 'Информация для клиентов — физических лиц',
         additionalDocuments: 'От Вас Банк вправе потребовать предоставление следующих дополнительных документов ' +
             'в зависимости от Ваших ответов на вопросы Анкеты:',
         additionalDocumentsFootnote: [
             {
                 text: 'Вы являетесь гражданином США /налоговым резидентом США. Вам необходимо предоставить заполненную форму W-9 (форма W-9 размещена на сайте \n' +
                     'Налоговой службы США: ' +
                     '<a href="http://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">http://www.irs.gov/pub/irs-pdf/fw9.pdf</a>).',
                 numbering: '1.'
             },
             {
                 text: 'Вы родились в США, но не являетесь гражданином США. Вам необходимо предоставить:',
                 numbering: '2.'
             },
         ],
         subAdditionalDocumentsFootnote: [
             {
                 text: 'свидетельство об утрате гражданства США по форме DS 4083 Бюро консульских ' +
                     'дел Государственного департамента США',
                 numbering: 'a.'
             },
             {
                 text: 'письменные объяснения в отношении отсутствия гражданства в США (например, указание причины, ' +
                     'по которой не было получено гражданство США по рождению).',
                 numbering: 'b.'
             },
         ],
         legalEntities: 'Информация для клиентов — юридических лиц',
         legalEntitiesDescription: 'От Вас Банк вправе потребовать предоставление следующих дополнительных ' +
             'документов в зависимости от Ваших ответов на вопросы Анкеты: соответствующие формы Налоговой ' +
             'службы США W-9 / W-8 и иные документы.',
         legalEntitiesLink: [
             {
                 title: 'Форма W-8BEN-E АО «Банк 131»',
                 link: W_8BEN_E
             },
             {
                 title: 'Форма W-8IMY АО «Банк 131»',
                 link: W_8IMY
             },
             {
                 title: 'Критерии отнесения клиентов к категории клиента-иностранного налогоплательщика \n' +
                     'и способы получения от них необходимой информации',
                 link: '/legal/ru/Grounds_for_classification_as_foreign_taxpayers.doc'
             },
             {
                 title: 'Согласие/отказ на передачу информации иностранным налоговым органам, уполномоченным органам РФ',
                 link: '/legal/ru/INFORMATION_PERMIT.docx'
             },
             {
                 title: 'Глоссарий',
                 link: glossary
             },
         ]
     }
}
import React from "react"

import '../styles/components/footnote.scss';
import PropTypes from "prop-types";

const Footnote = ({
                      content = [],
                      fontStyle = 'Normal',
                      numbering = 0
}) => {
    const classesContent = React.useMemo(() => {
        let cls = 'Footnote-Content'

        if (fontStyle) {
            cls += ` Footnote-Content-${fontStyle}`
        }

        return cls
    }, [fontStyle])

    const classesFootnote = React.useMemo(() => {
        let cls = 'Footnote'

        if (numbering > 0) {
            cls += ` Footnote-numbering${numbering}`
        }

        return cls
    }, [numbering])

    return (
        <div className={classesFootnote}>
            <div className="Footnote-Cell">
                <div className={classesContent}>
                    {content.map((item, index) => {
                        return <div key={`footnote_${index}`}>
                            {item.numbering && <div className="Footnote-Numbering">{item.numbering}</div>}
                            <div className="Footnote-text" dangerouslySetInnerHTML={{ __html: item.text }}/>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
};

export const FootnotePropTypes = {
    fontStyle: PropTypes.oneOf(['Normal', 'Italic']),
};

Footnote.propTypes = FootnotePropTypes;

export default Footnote;
